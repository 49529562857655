import { Box, IconButton, useColorScheme, useTheme } from "basta-ui";
import { MoonIcon, SunIcon } from "basta-ui";

export const ThemeSwitch = () => {
  const theme = useTheme();
  const { mode, setMode } = useColorScheme();

  const themeIcon = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({
        height: 48,
        width: 48,
        padding: 1.5,
        borderRadius: 9999,
        [theme.getColorSchemeSelector("dark")]: {
          backgroundColor: theme.vars.palette.primary[600],
          transform: "rotate(270deg)",
        },
        [theme.getColorSchemeSelector("light")]: {
          backgroundColor: theme.vars.palette.warning[500],
          transform: "rotate(0deg)",
        },
      })}
    >
      <Box
        component={SunIcon}
        color={theme.vars.palette.common.white}
        width={24}
        sx={{
          [theme.getColorSchemeSelector("dark")]: {
            display: "none",
          },
        }}
      />
      <Box
        component={MoonIcon}
        color={theme.vars.palette.common.white}
        width={24}
        sx={{
          [theme.getColorSchemeSelector("light")]: {
            display: "none",
          },
        }}
      />
    </Box>
  );

  return (
    <IconButton
      onClick={() => setMode(mode === "light" ? "dark" : "light")}
      tabIndex={-1}
      sx={{
        cursor: "pointer",
        position: { xs: "absolute", md: "fixed" },
        top: { xs: 0, md: 136 },
        right: { xs: 110, md: 0 },
        display: "flex",
        padding: 1,
        justifyContent: "center",
        alignItems: "end",
        height: 70,
        width: 64,
        borderBottomLeftRadius: 9999,
        borderBottomRightRadius: 9999,
        WebkitTapHighlightColor: "transparent",
        transform: { md: "rotate(90deg)" },
        zIndex: 2,

        ":focus-visible": {
          outline: "none",
          boxShadow: `0px 0px 0px 4px rgba(${theme.vars.palette.warning.mainChannel})`,

          "&::before": {
            display: "none",
          },
        },

        [theme.getColorSchemeSelector("light")]: {
          backgroundColor: theme.vars.palette.warning[200],
        },
        [theme.getColorSchemeSelector("dark")]: {
          backgroundColor: theme.vars.palette.neutral[700],
        },
      }}
    >
      {themeIcon}
    </IconButton>
  );
};
